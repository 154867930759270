'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Stack = styled.div<{ $noStretch?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;

  width: ${({ $noStretch }) => ($noStretch ? 'auto' : '100%')};
  margin-left: ${({ $noStretch }) => ($noStretch ? 'inherit' : 'auto')};
  margin-right: ${({ $noStretch }) => ($noStretch ? 'inherit' : 'auto')};

  & > * {
    width: 100%;
    align-items: stretch;
  }
`;

export const LeftStack = styled(Stack)`
  & > * {
    width: auto;
    align-items: flex-start;
  }
`;

export const RightStack = styled.div`
  /* border: 1px solid lime; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 50px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  & > * {
    /* border: 1px solid lime; */
    /* width: 100%; */
    /* align-items: stretch; */
  }
`;

export const CenteredStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
